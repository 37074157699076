// // IBG
// let ibg = document.querySelectorAll('.ibg');
//
// if (ibg) {
//     for (let item of ibg) {
//         let ibgImg = item.querySelector('img');
//         let ibgImgSrc = ibgImg.getAttribute('src');
//         item.style.backgroundImage = `url(${ibgImgSrc})`;
//     }
// }
//
// // Header Fixed
// let prevScrollPos = window.pageYOffset;
// let isHeaderFixed = false;
// let sidebar = document.querySelector('.sidebar');
//
// window.onscroll = function () {
//     let currentScrollPos = window.pageYOffset;
//
//     if (prevScrollPos > currentScrollPos) {
//         // Скролінг вверх
//         if (currentScrollPos === 0) {
//             // Якщо користувач проскролив вгору і більше немає куди, то видаляємо клас fixed-header
//             document.querySelector('header').classList.remove('fixed');
//             sidebar.classList.remove('header-fixed');
//             isHeaderFixed = false;
//         }
//     } else {
//         // Скролінг вниз
//         if (!isHeaderFixed) {
//             // Якщо header ще не фіксується, то додаємо клас fixed-header
//             document.querySelector('header').classList.add('fixed');
//             sidebar.classList.add('header-fixed');
//             isHeaderFixed = true;
//         }
//     }
//
//     prevScrollPos = currentScrollPos;
// };
//
//
// // SERVIDORES Item Show More
// if (document.querySelector('.item-servidores__info')) {
//     // $(".item-servidores__info").click(function () {
//     //     $(this).next().slideToggle(500);
//     //     $(this).toggleClass("active");
//     // });
// }
//
//
// // Динамічний адаптив
let da_elements = document.querySelectorAll('[data-da]');
let parents_original = [];
let attr_elements = [];
let attr_element = [];
let match_media = [];

for (let item of da_elements) {
    let parentChildren = item.parentElement.children;
    for (let i = 0; i < parentChildren.length; i++) {
        if (parentChildren[i] == item) {
            parents_original.push({
                'parent': item.parentElement,
                'index': i
            })
        }
    }
}

for (let item of da_elements) {
    attr_elements.push(item.dataset.da);
}

for (let i = 0; i < attr_elements.length; i++) {
    match_media.push(window.matchMedia("(max-width:" + attr_elements[i].split(', ')[2] + "px)"));
    match_media[i].addEventListener('change', dinamic_adapt);
}

function dinamic_adapt() {
    for (let i = 0; i < match_media.length; i++) {
        if (match_media[i].matches) {
            attr_element = attr_elements[i].split(', ');
            document.querySelector(`.${attr_element[0]}`).insertBefore(da_elements[i], document.querySelector(`.${attr_element[0]}`).children[attr_element[1]]);
        } else {
            parents_original[i].parent.insertBefore(da_elements[i], parents_original[i].parent.children[parents_original[i].index]);
        }
    }
}
dinamic_adapt();
//
//
// // Burger
// let body = document.querySelector('body');
// let burger = document.querySelector('.header__burger');
// let header_mobile = document.querySelector('.header-mobile');
// let header_mobile_close = document.querySelector('.top-mobile-header__btn');
// let balance_header = document.querySelector('.balance-header');
// let balance_header_body = document.querySelector('.balance-header__body');
//
// if (burger) {
//     burger.addEventListener('click', function (event) {
//         event.preventDefault();
//
//         header_mobile.classList.add('active');
//         body.classList.add('product-select');
//
//         if (balance_header) {
//             if (balance_header.classList.contains('active')) {
//                 balance_header_body.classList.add('active');
//             } else {
//                 balance_header_body.classList.remove('active');
//             }
//         }
//     })
//
//     header_mobile_close.addEventListener('click', function (event) {
//         event.preventDefault();
//
//         header_mobile.classList.remove('active');
//         body.classList.remove('product-select');
//
//         if (balance_header) {
//             if (balance_header.classList.contains('active')) {
//                 balance_header_body.classList.add('active');
//             } else {
//                 balance_header_body.classList.remove('active');
//             }
//         }
//     })
// }
//
//
// // News Slider
// if (document.querySelector('.news__swiper')) {
//     // const swiperNews = new Swiper('.news__swiper', {
//     //     // Optional parameters
//     //     loop: true,
//     //     slidesPerView: 5,
//     //     spaceBetween: 30,
//     //     autoplay: {
//     //         delay: 3000,
//     //     },
//     //
//     //     // Navigation arrows
//     //     navigation: {
//     //         nextEl: '.news__btn_next',
//     //         prevEl: '.news__btn_prev',
//     //     },
//     //
//     //     breakpoints: {
//     //         320: {
//     //             slidesPerView: 2,
//     //             spaceBetween: 20
//     //         },
//     //         700: {
//     //             slidesPerView: 3,
//     //             spaceBetween: 20
//     //         },
//     //         1100: {
//     //             slidesPerView: 4,
//     //             spaceBetween: 20
//     //         },
//     //         1300: {
//     //             slidesPerView: 4,
//     //             spaceBetween: 30
//     //         },
//     //         1600: {
//     //             slidesPerView: 5,
//     //             spaceBetween: 30
//     //         },
//     //         1920: {
//     //             slidesPerView: 5,
//     //             spaceBetween: 30
//     //         }
//     //     }
//     // });
// }
//

// // Menu scroll
// const menuLinks = document.querySelectorAll('a[href^="#"]');
//
// menuLinks.forEach(link => {
//     link.addEventListener('click', function (e) {
//         e.preventDefault();
//         const targetId = this.getAttribute('href').substring(1); // Отримуємо ідентифікатор якоря
//         const targetElement = document.getElementById(targetId); // Отримуємо відповідний елемент
//         if (targetElement) {
//             const offset = targetElement.getBoundingClientRect().top; // Визначаємо відстань до цільового елементу
//             window.scrollTo({
//                 top: offset,
//                 behavior: 'smooth' // Застосовуємо плавний скрол
//             });
//         }
//     });
// });
//
//
// // Paralax Images
// // var $container = $('body');
//
// // var container_w = $container.width(),
// //     container_h = $container.height();
//
// if (document.querySelector('.main-block__picture-block')) {
//     // $(window).on('mousemove.parallax', function (event) {
//     //     if (window.innerWidth > 992) {
//     //         var pos_x = event.pageX,
//     //             pos_y = event.pageY,
//     //             left = 0,
//     //             top = 0;
//     //
//     //         $('.parallax').each(function () {
//     //             var $parallaxContainer = $(this);
//     //             var speed = parseFloat($parallaxContainer.data('speed'));
//     //
//     //             left = container_w / 2 - pos_x;
//     //             top = container_h / 2 - pos_y;
//     //
//     //             TweenMax.to(
//     //                 $parallaxContainer,
//     //                 1,
//     //                 {
//     //                     css: {
//     //                         transform: 'translateX(' + (left * -speed) + 'px) translateY(' + (top * -speed) + 'px)',
//     //                     },
//     //                     ease: Expo.easeOut,
//     //                     overwrite: 'all'
//     //                 }
//     //             );
//     //         });
//     //
//     //         // Додавання паралаксу для '.contacto__image_cloud'
//     //         var $cloudImage = $('.contacto__image_cloud');
//     //         var speedForCloudImage = 0.12; // Задайте потрібну швидкість
//     //
//     //         left = container_w / 2 - pos_x;
//     //         top = (container_h / 2 - pos_y);
//     //
//     //         TweenMax.to(
//     //             $cloudImage,
//     //             1,
//     //             {
//     //                 css: {
//     //                     transform: 'translateX(' + (left * -speedForCloudImage) + 'px) translateY(' + (top * -speedForCloudImage) + 'px)',
//     //                     bottom: '90%'
//     //                 },
//     //                 ease: Expo.easeOut,
//     //                 overwrite: 'all'
//     //             }
//     //         );
//     //
//     //         TweenMax.to(
//     //             $layer_0,
//     //             10,
//     //             {
//     //                 css: {
//     //                     transform: 'rotate(' + left / 200 + 'deg)'
//     //                 },
//     //                 ease: Expo.easeOut,
//     //                 overwrite: 'none'
//     //             }
//     //         );
//     //     }
//     // });
//
// }
//
// // Scroll Show Block
// // Функція, яка буде викликатися, коли елемент стає видимим
// function handleIntersection(entries, observer) {
//     entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//             entry.target.classList.add("visible");
//             observer.unobserve(entry.target);
//         }
//     });
// }
//
// // Створення спостерігача (observer)
// const observer = new IntersectionObserver(handleIntersection, {
//     root: null, // Відстежувати взаємодію відносно кореневого елементу (null - весь viewport)
//     threshold: 0.2, // Визначає, коли відстежувач повинен активуватися (50% видимості)
// });
//
// // Отримати всі елементи з класом "animated-block" і відстежувати їх
// const animatedBlocks = document.querySelectorAll(".animated-block");
// animatedBlocks.forEach((block) => {
//     observer.observe(block);
// });
//
//
// document.addEventListener('DOMContentLoaded', function () {
//     if (document.querySelector('.main-block__picture-block') && document.querySelector('.main-block__content')) {
//         var block = document.querySelector('.content-main-block');
//         var cloud_1 = document.querySelector('.main-block__picture_cloud-1');
//         var cloud_2 = document.querySelector('.main-block__picture_cloud-2');
//         var bird = document.querySelector('.main-block__picture_bird');
//         var dragon = document.querySelector('.main-block__picture_dragon');
//
//         // gsap.from(block, {
//         //     opacity: 0,
//         //     y: 500,
//         //     scale: 0.9,
//         //     rotation: -10,
//         //     duration: 2.5,
//         //     ease: 'elastic.out(1, 0.5)',
//         //     delay: 0.3,
//         // });
//
//         // gsap.from(cloud_1, {
//         //     opacity: 0,
//         //     x: 500,
//         //     duration: 1,
//         //     ease: 'elastic.out(1, 0.5)',
//         //     delay: 1.5,
//         // });
//         //
//         // gsap.from(cloud_2, {
//         //     opacity: 0,
//         //     x: -500,
//         //     duration: 1,
//         //     ease: 'elastic.out(1, 0.5)',
//         //     delay: 1.5,
//         // });
//         //
//         // gsap.from(bird, {
//         //     opacity: 0,
//         //     x: 500,
//         //     duration: 1,
//         //     ease: 'elastic.out(1, 0.5)',
//         //     delay: 2,
//         // });
//         //
//         // gsap.from(dragon, {
//         //     opacity: 0,
//         //     x: -1000,
//         //     duration: 1,
//         //     ease: 'elastic.out(1, 0.5)',
//         //     delay: 2.5,
//         // });
//     }
// });
//
//
// // Categories Order
// let categories_orders = document.querySelectorAll('.item-order-top-cards-categories');
//
// if (categories_orders) {
//     categories_orders.forEach(function (categories_order) {
//         categories_order.addEventListener('click', function (event) {
//             event.preventDefault();
//
//             let arrow_up = categories_order.querySelector('.item-order-top-cards-categories__arrow-up');
//             let arrow_dowm = categories_order.querySelector('.item-order-top-cards-categories__arrow-down');
//
//             if (!categories_order.classList.contains('active')) {
//                 categories_orders.forEach(function (item) {
//                     item.classList.remove('active');
//
//                     arrow_up = item.querySelector('.item-order-top-cards-categories__arrow-up');
//                     arrow_dowm = item.querySelector('.item-order-top-cards-categories__arrow-down');
//
//                     arrow_up.classList.remove('hide');
//                     arrow_dowm.classList.remove('hide');
//                 })
//
//                 categories_order.classList.add('active');
//
//                 arrow_up = categories_order.querySelector('.item-order-top-cards-categories__arrow-up');
//                 arrow_dowm = categories_order.querySelector('.item-order-top-cards-categories__arrow-down');
//                 arrow_up.classList.add('hide');
//             } else {
//                 if (arrow_up.classList.contains('hide')) {
//                     arrow_up.classList.remove('hide');
//                     arrow_dowm.classList.add('hide');
//                 } else {
//                     arrow_dowm.classList.remove('hide');
//                     arrow_up.classList.add('hide');
//                 }
//             }
//         })
//     })
// }
//
//
// // Dropdown
// let dropdownButtons = document.querySelectorAll('.dropdown__button');
//
// if (dropdownButtons) {
//     dropdownButtons.forEach(function (dropdownButton) {
//         dropdownButton.addEventListener('click', function (event) {
//             event.preventDefault();
//
//             this.classList.toggle('active');
//
//             let dropdownList = dropdownButton.nextElementSibling;
//             let dropdownListItems = dropdownList.querySelectorAll('.dropdown__list-item');
//             let dropdownInputHidden = dropdownList.nextElementSibling;
//
//             dropdownList.classList.toggle('visible');
//
//             dropdownListItems.forEach(function (listItem) {
//                 listItem.addEventListener('click', function (event) {
//                     event.stopPropagation();
//
//                     dropdownButton.innerHTML = this.innerHTML;
//                     dropdownInputHidden.value = this.dataset.dropdown;
//                     dropdownList.classList.remove('visible');
//                     dropdownButton.classList.remove('active');
//                 })
//             })
//         })
//     })
// }
//
//
// // Selected product
// let item_categories_cards = document.querySelectorAll('.item-cards-categories');
// let select_product = document.querySelector('.select-product');
// let select_product_btn_close = document.querySelector('.select-product__btn-close');
//
// if (item_categories_cards) {
//     item_categories_cards.forEach(function (item_categories_card) {
//         item_categories_card.addEventListener('click', function (event) {
//             event.preventDefault();
//
//             select_product.classList.add('active');
//             body.classList.add('product-select');
//         })
//     })
//
//     if (select_product_btn_close) {
//         select_product_btn_close.addEventListener('click', function (event) {
//             event.preventDefault();
//
//             select_product.classList.remove('active');
//             body.classList.remove('product-select');
//         })
//     }
// }
//
//
// // Popup.tsx
// let login_btn_popup_authorization = document.querySelector('.login-panel-categories__btn');
// let popup_authorization = document.querySelector('.popup-authorization');
// let login_btn_popup_top_up = document.querySelector('.top-up-btn');
// let popup_top_up = document.querySelector('.popup-top-up');
// let popup_btns_close = document.querySelectorAll('.inner-popup__close');
// let popups = document.querySelectorAll('.popup');
//
// if (login_btn_popup_authorization) {
//     login_btn_popup_authorization.addEventListener('click', function (event) {
//         event.preventDefault();
//
//         popup_authorization.classList.add('open');
//         setTimeout(function () {
//             popup_authorization.classList.add('close');
//         }, 800);
//     })
// }
//
// if (login_btn_popup_top_up) {
//     login_btn_popup_top_up.addEventListener('click', function (event) {
//         event.preventDefault();
//
//         popup_top_up.classList.add('open');
//         setTimeout(function () {
//             popup_top_up.classList.add('close');
//         }, 800);
//     })
// }
//
// if (popup_btns_close) {
//     popup_btns_close.forEach(function (popup_btn_close) {
//         popup_btn_close.addEventListener('click', function (event) {
//             event.preventDefault();
//
//             popupClose();
//         })
//     })
// }
//
// function popupClose() {
//     popups.forEach(function (popup) {
//         popup.classList.remove('open');
//         popup.classList.remove('close');
//     })
// }
//
// document.addEventListener('keydown', function (event) {
//     if (event.code === 'Escape') {
//         popupClose();
//     }
// });
//
// document.addEventListener('click', function (e) {
//     popups.forEach(function (popup) {
//         if (popup.classList.contains('close') && !popup.querySelector('.popup__content').contains(e.target)) {
//             popupClose();
//         }
//     })
// });
//
//
// // Roulette Games
// let roulette_games_links = document.querySelectorAll('.menu-filter-roulette__link');
//
// if (roulette_games_links) {
//     roulette_games_links.forEach(function (roulette_games_link) {
//         roulette_games_link.addEventListener('click', function (event) {
//             event.preventDefault();
//
//             roulette_games_links.forEach(function (item) {
//                 item.classList.remove('active');
//             })
//
//             roulette_games_link.classList.add('active');
//         })
//     })
// }
//
//
// // Roulette Slider
// if (document.querySelector('.games-filter-roulette__swiper')) {
//     // const swiperRoulette = new Swiper('.games-filter-roulette__swiper', {
//     //     // Optional parameters
//     //     // loop: true,
//     //     slidesPerView: 10,
//     //     spaceBetween: 20,
//     //     preloadImages: true,
//     //     // centeredSlides: true,
//     //     // allowTouchMove: false,
//     //     // freeMode: true,
//     //
//     //     breakpoints: {
//     //         320: {
//     //             slidesPerView: 3,
//     //             spaceBetween: 10
//     //         },
//     //         480: {
//     //             slidesPerView: 3,
//     //             spaceBetween: 10
//     //         },
//     //         767: {
//     //             slidesPerView: 5,
//     //             spaceBetween: 10
//     //         },
//     //         // when window width is >= 320px
//     //         991: {
//     //             slidesPerView: 5,
//     //             spaceBetween: 10
//     //         },
//     //         // when window width is >= 480px
//     //         1200: {
//     //             slidesPerView: 5,
//     //             spaceBetween: 20
//     //         },
//     //         // when window width is >= 640px
//     //         1920: {
//     //             slidesPerView: 5,
//     //             spaceBetween: 20
//     //         }
//     //     },
//     // });
//
//     let roulette_games_btn = document.querySelector('.filter-roulette__btn');
//
//     let slidesToScroll = 27;
//     // let currentIndex = swiperRoulette.activeIndex;
//     //
//     // roulette_games_btn.addEventListener('click', function () {
//     //     currentIndex += slidesToScroll;
//     //
//     //     if (currentIndex >= swiperRoulette.slides.length) {
//     //         currentIndex = swiperRoulette.slides.length - 1;
//     //     }
//     //
//     //     swiperRoulette.update();
//     //
//     //     swiperRoulette.slides.forEach(function (slide) {
//     //         // Знаходимо елемент <span> з відповідним класом
//     //         var spanElement = slide.querySelector('.item-games-filter-roulette__name');
//     //
//     //         console.log(spanElement);
//     //
//     //         // Перевіряємо, чи існує такий елемент і чи має він вміст (якщо не має, то завантажуємо контент)
//     //         if (spanElement && !spanElement.textContent) {
//     //             // Завантаження контенту для <span>
//     //             // Наприклад, якщо у вас є дані для цього блоку, ви можете встановити їх так:
//     //             spanElement.textContent = 'Ваш текст чи дані';
//     //         } else {
//     //             // console.log('no');
//     //         }
//     //
//     //         // Тут ви можете додати код для завантаження інших типів контенту для слайда
//     //     });
//     //
//     //     swiperRoulette.slideTo(currentIndex, 10000, false, {
//     //         cubicBezierTransition: 'cubic-bezier(0.32, 1.03, 0.67, 0.84)',
//     //     });
//     // });
// }
//
//
// // Dropdown Menu Inner
// if (document.querySelector('.dropdown-menu-inner__button')) {
//     if (window.innerWidth <= 1100) {
//         dropdownMenuInner();
//         dropdownMenuInnerButtonItem();
//     }
// }
//
// function dropdownMenuInner() {
//     // $(".dropdown-menu-inner__button").click(function () {
//     //     $(this).next().slideToggle(500);
//     //     $(this).toggleClass("active");
//     // });
// }
// function dropdownMenuInnerButtonItem() {
//     let dropdownMenuInnerButton = document.querySelector('.dropdown-menu-inner__button');
//     let dropdownMenuInnerButtonItem = dropdownMenuInnerButton.querySelector('.menu-inner__link');
//
//     dropdownMenuInnerButtonItem.addEventListener('click', function (event) {
//         event.preventDefault();
//     })
// }
//
//
// // Calculator
// const giveInput = document.querySelector('.calculator-replenishment-bonuses__input_give');
// const getInput = document.querySelector('.calculator-replenishment-bonuses__input_get');
//
// if (giveInput && getInput) {
//     // Встановлюємо курс обміну
//     let dollarCurs = 0.45;
//     let euroCurs = 0.4;
//
//     // Функція для обчислення значень
//     function calculateValues() {
//         const giveValue = parseFloat(giveInput.value);
//         if (!isNaN(giveValue)) {
//             if (document.querySelector('.dropdown__input-hidden_valuta').value === 'dollars') {
//                 const getValue = giveValue * dollarCurs;
//                 getInput.value = (getValue % 1 === 0) ? getValue.toFixed(0) : getValue.toFixed(2);
//             } else if (document.querySelector('.dropdown__input-hidden_valuta').value === 'euro') {
//                 const getValue = giveValue * euroCurs;
//                 getInput.value = (getValue % 1 === 0) ? getValue.toFixed(0) : getValue.toFixed(2);
//             }
//         } else {
//             getInput.value = "";
//         }
//     }
//
//     // Слухачі подій для вводу чисел
//     giveInput.addEventListener('input', calculateValues);
//     getInput.addEventListener('input', function () {
//         const getValue = parseFloat(getInput.value);
//         if (!isNaN(getValue)) {
//             if (document.querySelector('.dropdown__input-hidden_valuta').value === 'dollars') {
//                 const giveValue = getValue / dollarCurs;
//                 giveInput.value = (giveValue % 1 === 0) ? giveValue.toFixed(0) : giveValue.toFixed(2);
//             } else if (document.querySelector('.dropdown__input-hidden_valuta').value === 'euro') {
//                 const giveValue = getValue / euroCurs;
//                 giveInput.value = (giveValue % 1 === 0) ? giveValue.toFixed(0) : giveValue.toFixed(2);
//             }
//         } else {
//             giveInput.value = "";
//         }
//     });
// }
//
//
// // News Open
// let news_open = document.querySelector('.news-open');
// let news_open_btns = document.querySelectorAll('.news-open-btn');
// let news_open_close = document.querySelector('.news-open__btn');
//
// if (news_open_btns) {
//     news_open_btns.forEach(function (news_open_btn) {
//         news_open_btn.addEventListener('click', function (event) {
//             event.preventDefault();
//
//             news_open.classList.add('active');
//             body.classList.add('product-select');
//
//             setTimeout(function () {
//                 news_open.classList.add('close');
//             }, 500);
//         })
//     })
//
//     if (news_open_close) {
//         news_open_close.addEventListener('click', function (event) {
//             event.preventDefault();
//
//             newsOpenClose();
//         })
//     }
//
//     document.addEventListener('keydown', function (event) {
//         if (news_open && event.code === 'Escape') {
//             newsOpenClose();
//         }
//     });
//
//     document.addEventListener('click', function (e) {
//         if (news_open && news_open.classList.contains('close') && !news_open.querySelector('.news-open__body').contains(e.target)) {
//             newsOpenClose();
//         }
//     });
//
//     function newsOpenClose() {
//         news_open.classList.remove('active');
//         news_open.classList.remove('close');
//         body.classList.remove('product-select');
//     }
// }
//
//
// // Swiper Purchases
// if (document.querySelector('.purchases__swiper')) {
//     // const purchasesSwiper = new Swiper('.purchases__swiper', {
//     //     spaceBetween: 20,
//     //     allowTouchMove: false,
//     //
//     //     pagination: {
//     //         el: ".purchases-slider__pagination",
//     //         clickable: true,
//     //         renderBullet: function (index, className) {
//     //             return '<span class="' + className + '">' + (index + 1) + "</span>";
//     //         },
//     //     },
//     //
//     //     // Navigation arrows
//     //     navigation: {
//     //         nextEl: '.purchases-slider__btn_next',
//     //         prevEl: '.purchases-slider__btn_prev',
//     //     },
//     // });
// }
//
// // Swiper Donation
// if (document.querySelector('.history-profile__swiper')) {
//     // const donationSwiper = new Swiper('.history-profile__swiper', {
//     //     spaceBetween: 20,
//     //     allowTouchMove: false,
//     //
//     //     pagination: {
//     //         el: ".history-profile__pagination",
//     //         clickable: true,
//     //         renderBullet: function (index, className) {
//     //             return '<span class="' + className + '">' + (index + 1) + "</span>";
//     //         },
//     //     },
//     //
//     //     // Navigation arrows
//     //     navigation: {
//     //         nextEl: '.history-profile__btn_next',
//     //         prevEl: '.history-profile__btn_prev',
//     //     },
//     // });
// }
//
// // Swiper Users
// if (document.querySelector('.users__swiper')) {
//     // const usersSwiper = new Swiper('.users__swiper', {
//     //     spaceBetween: 20,
//     //     allowTouchMove: false,
//     //
//     //     pagination: {
//     //         el: ".users__pagination",
//     //         clickable: true,
//     //         renderBullet: function (index, className) {
//     //             return '<span class="' + className + '">' + (index + 1) + "</span>";
//     //         },
//     //     },
//     //
//     //     // Navigation arrows
//     //     navigation: {
//     //         nextEl: '.users__btn_next',
//     //         prevEl: '.users__btn_prev',
//     //     },
//     // });
// }
//
//
// // Input Quantity
// let input_quantity = document.querySelector('.characteristics-select-product__input_quantity');
// let input_quantity_minus = document.querySelector('.characteristics-select-product__minus');
// let input_quantity_plus = document.querySelector('.characteristics-select-product__plus');
//
// if (input_quantity) {
//     input_quantity_plus.addEventListener('click', function (event) {
//         event.preventDefault();
//
//         input_quantity.value = +input_quantity.value + 1;
//     })
//
//     input_quantity_minus.addEventListener('click', function (event) {
//         event.preventDefault();
//
//         if (input_quantity.value > 1) {
//             input_quantity.value = input_quantity.value - 1;
//         }
//     })
// }