import styled from "styled-components";

export const PurchasesStyled = styled.main`
  
    p {
      font-size: 20px;
      color: #fff;
      height: auto;
    }
    
`
