export const currencyList: any = [
    {
        icon: "$",
        value: 1,
        currency: "USD"
    },
    {
        icon: "€",
        value: 1.08,
        currency: "EUR"
    },
    {
        icon: "₴",
        value: 0.0261,
        currency: "UAH"
    },
    {
        icon: "£",
        value: 1.19,
        currency: "GBR"
    },
    {
        icon: "₽",
        value: 0.01054,
        currency: "RUB"
    }
]